"use client";

import { useState, useEffect } from "react";

type Square = 'X' | 'O' | null;
type WinnerResult = {
  winner: Square;
  line: number[];
}
type Scores = {
  X: number;
  O: number;
}
type GameMode = '1P' | '2P';

const XMark = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" stroke="currentColor" strokeWidth="8">
    <line x1="25" y1="25" x2="75" y2="75" />
    <line x1="75" y1="25" x2="25" y2="75" />
  </svg>
);

const OMark = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" stroke="currentColor" strokeWidth="8">
    <circle cx="50" cy="50" r="25" />
  </svg>
);

export default function Home() {
  const [board, setBoard] = useState<Square[]>(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [scores, setScores] = useState<Scores>({ X: 0, O: 0 });
  const [gameMode, setGameMode] = useState<GameMode>('2P');

  useEffect(() => {
    if (gameMode === '1P' && !isXNext && !calculateWinner(board).winner) {
      const timer = setTimeout(makeComputerMove, 500);
      return () => clearTimeout(timer);
    }
  }, [board, isXNext, gameMode]);

  const makeComputerMove = () => {
    const availableSquares = board.map((square, i) => square === null ? i : null).filter((i) => i !== null) as number[];
    if (availableSquares.length === 0) return;

    // 90% chance to play smart, 10% chance to play randomly
    if (Math.random() < 0.9) {
      // Check for immediate win
      for (const move of availableSquares) {
        const boardCopy = [...board];
        boardCopy[move] = 'O';
        if (calculateWinner(boardCopy).winner === 'O') {
          handleMove(move);
          return;
        }
      }

      // Block opponent's win 90% of the time
      if (Math.random() < 0.9) {
        for (const move of availableSquares) {
          const boardCopy = [...board];
          boardCopy[move] = 'X';
          if (calculateWinner(boardCopy).winner === 'X') {
            handleMove(move);
            return;
          }
        }
      }

      // Strategic moves with 80% probability
      if (Math.random() < 0.8) {
        // Prefer center
        if (availableSquares.includes(4)) {
          handleMove(4);
          return;
        }

        // Then corners
        const corners = [0, 2, 6, 8].filter(corner => availableSquares.includes(corner));
        if (corners.length > 0) {
          handleMove(corners[Math.floor(Math.random() * corners.length)]);
          return;
        }
      }
    }

    // Random move as fallback
    handleMove(availableSquares[Math.floor(Math.random() * availableSquares.length)]);
  };

  const minimax = (boardState: Square[], depth: number, isMaximizing: boolean): number => {
    const result = calculateWinner(boardState);
    
    if (result.winner === 'O') return 10 - depth; // Prefer quicker wins
    if (result.winner === 'X') return depth - 10; // Prefer blocking quicker losses
    if (boardState.every(square => square !== null)) return 0;
    if (depth === 0) return evaluatePosition(boardState);

    const available = boardState.map((square, i) => square === null ? i : null).filter((i) => i !== null) as number[];

    if (isMaximizing) {
      let bestScore = -Infinity;
      for (const move of available) {
        const boardCopy = [...boardState];
        boardCopy[move] = 'O';
        const score = minimax(boardCopy, depth - 1, false);
        bestScore = Math.max(score, bestScore);
      }
      return bestScore;
    } else {
      let bestScore = Infinity;
      for (const move of available) {
        const boardCopy = [...boardState];
        boardCopy[move] = 'X';
        const score = minimax(boardCopy, depth - 1, true);
        bestScore = Math.min(score, bestScore);
      }
      return bestScore;
    }
  };

  const evaluatePosition = (boardState: Square[]): number => {
    // Simple position evaluation
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];

    let score = 0;
    for (const [a, b, c] of lines) {
      const lineValues = [boardState[a], boardState[b], boardState[c]];
      const oCount = lineValues.filter(v => v === 'O').length;
      const xCount = lineValues.filter(v => v === 'X').length;
      
      if (oCount > 0 && xCount === 0) score += oCount;
      if (xCount > 0 && oCount === 0) score -= xCount;
    }
    
    return score;
  };

  const handleMove = (index: number) => {
    if (board[index] || calculateWinner(board).winner) return;
    const newBoard = board.slice();
    newBoard[index] = isXNext ? "X" : "O";
    setBoard(newBoard);
    setIsXNext(!isXNext);

    const result = calculateWinner(newBoard);
    if (result.winner && (result.winner === 'X' || result.winner === 'O')) {
      setScores(prev => ({
        ...prev,
        [result.winner as 'X' | 'O']: prev[result.winner as 'X' | 'O'] + 1
      }));
    }
  };

  const handleClick = (index: number) => {
    if (gameMode === '1P' && !isXNext) return; // Prevent clicks during computer turn
    handleMove(index);
  };

  const calculateWinner = (squares: Square[]): WinnerResult => {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];
    for (const [a, b, c] of lines) {
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return { winner: squares[a], line: [a, b, c] };
      }
    }
    return { winner: null, line: [] };
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
  };

  const resetAll = () => {
    resetGame();
    setScores({ X: 0, O: 0 });
  };

  const { winner, line } = calculateWinner(board);
  const isDraw = !winner && board.every(square => square);

  const getStatusText = () => {
    if (winner) return `${winner} WINS!`;
    if (isDraw) return "DRAW!";
    if (gameMode === '1P') {
      return isXNext ? "YOUR TURN" : "COMPUTER THINKING...";
    }
    return isXNext ? "X TURN" : "O TURN";
  };

  return (
    <div className="tic-tac-toe">
      <div className="game-container">
        {/* Desktop scoreboard */}
        <div className="score-x desktop">
          <div className="score-label">Player X</div>
          <div className="score-value">{scores.X}</div>
        </div>

        <div className="game-center">
          <div className="status">
            <span>{getStatusText()}</span>
          </div>
          <div className="board">
            {board.map((value, index) => (
              <button
                key={index}
                className={`square ${line.includes(index) ? 'winning-line' : ''}`}
                onClick={() => handleClick(index)}
                disabled={!!value || !!winner}
                aria-label={`Square ${index + 1}${value ? ': ' + value : ''}`}
              >
                {value === 'X' ? <XMark /> : value === 'O' ? <OMark /> : null}
              </button>
            ))}
          </div>
        </div>

        <div className="score-o desktop">
          <div className="score-label">Player O</div>
          <div className="score-value">{scores.O}</div>
        </div>
      </div>

      {/* Mobile scoreboard */}
      <div className="mobile-scoreboard">
        <div className="score-item">
          <div className="score-label">Player X</div>
          <div className="score-value">{scores.X}</div>
        </div>
        <div className="score-separator">—</div>
        <div className="score-item">
          <div className="score-label">Player O</div>
          <div className="score-value">{scores.O}</div>
        </div>
      </div>

      <div className="controls">
        <div className="mode-toggle">
          <button 
            className={`toggle-btn ${gameMode === '1P' ? 'active' : ''}`}
            onClick={() => {resetAll(); setGameMode('1P');}}
          >
            1P
          </button>
          <button 
            className={`toggle-btn ${gameMode === '2P' ? 'active' : ''}`}
            onClick={() => {resetAll(); setGameMode('2P');}}
          >
            2P
          </button>
        </div>

        {(winner || isDraw) ? (
          <button className="play-again" onClick={resetGame}>
            PLAY AGAIN
          </button>
        ) : (
          <div className="play-again-placeholder"></div>
        )}

        <button className="reset-score" onClick={resetAll}>
          RESET SCORES
        </button>
      </div>
    </div>
  );
}
